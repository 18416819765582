// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierGraphBlockById(
    $blockId: ID!
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
  ) {
    graph: dossierGraphBlockById(
      blockId: $blockId
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
    ) {
      data
    }
  }
`;

export default QUERY;
